import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState, useContext } from "react";
import { CartContext } from "../context/CartContext";

import EstadoPedido from "../components/EstadoPedido";

import "../css/css-pages/indexpage.css";
import logo from "../img/ERIKA.webp";

function IndexPage() {
  const { clearCart } = useContext(CartContext);
  const [showApproved, setShowApproved] = useState(false);
  const [estado, setEstado] = useState("Aprobado");
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const status = params.get("status");

    if (status === "approved" || status === "in_process") {
      setShowApproved(true);
      setEstado("Aprobado");
      clearCart();

      const externalReference = params.get("external_reference");
      if (externalReference) {
        const codCompra = parseInt(externalReference, 10);
        actualizarEstadoCompra(codCompra);
      }
    } else if (status === "failure") {
      setShowApproved(true);
      setEstado("Rechazado");
    } else {
      setShowApproved(false);
    }
  }, [location]);

  // Función para actualizar el estado de la compra
  const actualizarEstadoCompra = async (codCompra) => {
    try {
      const response = await fetch('https://api.erikapolleria.com/index.php?route=compra', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          codCompra: codCompra,
          estado: "S"
        })
      });

      if (!response.ok) {
        throw new Error('Error al actualizar el estado de la compra');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const [isReadyForInstall, setIsReadyForInstall] = React.useState(false);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (event) => {
      // Prevent the mini-infobar from appearing on mobile.
      event.preventDefault();
      console.log("👍", "beforeinstallprompt", event);
      // Stash the event so it can be triggered later.
      window.deferredPrompt = event;
      // Remove the 'hidden' class from the install button container.
      setIsReadyForInstall(true);
    });
  }, []);

  async function downloadApp() {
    console.log("👍", "butInstall-clicked");
    const promptEvent = window.deferredPrompt;
    if (!promptEvent) {
      // The deferred prompt isn't available.
      console.log("oops, no prompt event guardado en window");
      return;
    }
    // Show the install prompt.
    promptEvent.prompt();
    // Log the result
    const result = await promptEvent.userChoice;
    console.log("👍", "userChoice", result);
    // Reset the deferred prompt variable, since
    // prompt() can only be called once.
    window.deferredPrompt = null;
    // Hide the install button.
    setIsReadyForInstall(false);
  }

  return (
    <div className="Index">
      {showApproved && (
        <EstadoPedido estado={estado} />
      )}
      {!showApproved && (
        <div className="App-header">
          <div className="divArriba">
            <img src={logo} className="App-logo" alt="logo" />
          </div>
          <div className="divAbajo">
            <Link className="btn btn App-link" to="/rubros">
              Ir a la tienda
            </Link>
            <br/>



            {isReadyForInstall && (
          <button className="btn btn-download App-link my-2" onClick={downloadApp}>Instalar aplicación</button>
        )}

            <br/>
            <a
              className="linkPuntaje solo"
              href="https://g.page/r/CTIhGjN9BZiUEBM/review"
            >
              ¿Cómo te atendimos?
            </a>
            <p className="linkPuntaje">
              Abierto de Lunes a Viernes de 9 a.m. a 1:30 p.m. y de 5:30 a 9:30
              p.m.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default IndexPage;