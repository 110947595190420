import React, { useEffect, useState } from "react";
import "../css/css-pages/pedidospage.css";

import { AlertCircleOutline } from "react-ionicons";
import Avatar from "react-avatar";

import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import { Link } from "react-router-dom";

function PedidosPage() {
  const [pedidos, setPedidos] = useState([]);
  const [detallesPedido, setDetallesPedido] = useState([]);
  const [pedidoSeleccionado, setPedidoSeleccionado] = useState(null);

  useEffect(() => {
    document.title = "Mis Pedidos - Erika";
    fetchPedidos();
  }, []);

  const userIdGuardado = localStorage.getItem("userId");

  const fetchPedidos = async () => {
    try {
      const response = await fetch(
        `https://api.erikapolleria.com/index.php?route=compra&codCliente=${userIdGuardado}`
      );
      const data = await response.json();
      setPedidos(data);
    } catch (error) {
      console.error("Error al obtener los pedidos:", error);
    }
  };

  const fetchDetallesPedido = async (pedido) => {
    try {
      const response = await fetch(
        `https://api.erikapolleria.com/index.php?route=orden&codOrdenDeCompra=${pedido.cod}`
      );
      const data = await response.json();
      setDetallesPedido(data);
      setPedidoSeleccionado(pedido);
    } catch (error) {
      console.error("Error al obtener los detalles del pedido:", error);
    }
  };

  const calcularTotal = () => {
    const subtotal = parseFloat(detallesPedido[0]?.sumaPrecioTotal || 0);
    const costoEntrega = pedidoSeleccionado?.delivery === "L" ? 1000 : 0;
    return (subtotal + costoEntrega).toFixed(2);
  };

  return (
    <div>
      <NavBar />
      <div className="container d-flex flex-column">
        <h1 className="my-4 fw-bold">Mis Pedidos</h1>
        {pedidos.length > 0 ? (
          <div className="row mx-md-5">
            {pedidos.map((pedido) => (
              <div className={`col-${pedidos.length > 1 ? "md-6" : "12 justify-content-center d-flex"}`} key={pedido.cod}>
                <div
                  className="card borderErika rounded mb-3 cursorPointer"
                  data-bs-toggle="modal"
                  data-bs-target="#detallesPedidoModal"
                  onClick={() => fetchDetallesPedido(pedido)}
                >
                  <div className="card-body px-2 py-2">
                    <div className="d-flex align-items-center">
                      <div className="bg-body-secondary rounded p-4 h-100">
                        <h4 className="text-black m-0">#{pedido.cod}</h4>
                      </div>

                      <div className="ps-1">
                        <div className="d-flex justify-content-between">
                          <div className="me-1 pt-1">
                            {pedido.estado === "S" || pedido.estado === "C" ? (
                              <span className="fw-bold">CONFIRMADO</span>
                            ) : (
                              <span className="fw-bold">FINALIZADO</span>
                            )}
                          </div>

                          <p className="text-secondary fs-6 m-0 pt-1">
                            {" • "}
                            {pedido.created_at.split(', ')[0].split('/').map((part, index) => index === 2 ? part.slice(-2) : part).join('/')}
                          </p>
                        </div>

                        <div className="d-flex pb-1">
                          <div className="me-1">
                            {pedido.formaDePago === "E" ? (
                              <span
                                className="badge rounded-pill"
                                style={{ backgroundColor: "#9abb67" }}
                              >
                                Efectivo
                              </span>
                            ) : (
                              <span
                                className="badge rounded-pill"
                                style={{ backgroundColor: "#30a0dd" }}
                              >
                                Mercado Pago
                              </span>
                            )}
                          </div>
                          <div>
                            {pedido.delivery === "L" ? (
                              <span className="badge rounded-pill text-bg-warning">
                                Delivery
                              </span>
                            ) : (
                              <span
                                className="badge rounded-pill"
                                style={{ backgroundColor: "#f97316" }}
                              >
                                Retirar
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="text-secondary d-flex">
                          <div className="me-1">
                            {" "}
                            ${pedido.monto} {" • "}
                          </div>
                          <div> {pedido.cantOrden} productos</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="d-flex align-items-center flex-column">
            <p className="vacio sinPedidos text-center">
              <AlertCircleOutline color={"#00000"} height="25px" width="25px" />
              <span className="vacioText">No tienes pedidos realizados</span>
            </p>

            <Link
              to="/rubros"
              className="btn btn-danger borderErika btnRegis my-3"
            >
              Ir a la tienda
            </Link>
          </div>
        )}
      </div>

      <div
        className="modal fade"
        id="detallesPedidoModal"
        tabIndex="-1"
        aria-labelledby="detallesPedidoModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content borderErika">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="detallesPedidoModalLabel">
                Detalles del Pedido N° {pedidoSeleccionado?.cod}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="table-responsive">
                <table className="table table-striped table-bordered text-start">
                  <thead>
                    <tr>
                      <th>Producto</th>
                      <th>Cantidad</th>
                      <th>P. Unitario</th>
                      <th>P. Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {detallesPedido.map((detalle, index) => (
                      <tr key={index}>
                        <td>{detalle.nombre}</td>
                        <td>
                          {detalle.cantidad} {detalle.unidad}
                        </td>
                        <td>${detalle.precioUnitario}</td>
                        <td>${detalle.precioTotal}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan="3" className="text-end">
                        Subtotal:
                      </td>
                      <td className="fw-bold">
                        ${detallesPedido[0]?.sumaPrecioTotal || "0.00"}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3" className="text-end">
                        Delivery:
                      </td>
                      <td className="fw-bold">
                        $
                        {pedidoSeleccionado?.delivery === "L"
                          ? "1000.00"
                          : "0.00"}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3" className="text-end fw-bold h5">
                        Total del pedido:
                      </td>
                      <td className="fw-bold h5">${calcularTotal()}</td>
                    </tr>
                  </tfoot>
                </table>
                {pedidoSeleccionado?.delivery === "L" ? (
                  <p className="text-danger mx-1 mb-0">
                    El costo de entrega mínimo es de $1.000, y puede variar
                    según tu dirección. ¡Te avisaremos si hay alguna diferencia!
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default PedidosPage;