// CartContext.js
import React, { createContext, useState, useEffect } from "react";

const CartContext = createContext();

const CartProvider = ({ children }) => {

  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : [];
  });

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  const [user, setUser] = useState(null);

  useEffect(() => {
    // Obtener usuario desde localStorage
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(storedUser); // No se necesita JSON.parse si es una cadena simple
    }
  }, []);

  const updateUser = (newUser) => {
    setUser(newUser);
  };

  const addItemToCart =(newItem) => {
    setCart((prevCart) => {
      const existingItemIndex = prevCart.findIndex(item => item.idPro === newItem.idPro);

      if(existingItemIndex >=0) {
        const updateCart = [...prevCart];
        updateCart[existingItemIndex].cantidad += newItem.cantidad;
        return updateCart;
      }
      else {
        return [...prevCart, newItem];
      }
    });
  };

  const subtractItemToCart = (newItem) => {
    setCart((prevCart) => {
      const existingItemIndex = prevCart.findIndex(item => item.idPro === newItem.idPro);

      if (existingItemIndex >= 0) {
        const updatedCart = [...prevCart];
        updatedCart[existingItemIndex].cantidad -= newItem.cantidad;

        // Si la cantidad es menor o igual a 0, eliminar el insumo del carrito
        if (updatedCart[existingItemIndex].cantidad <= 0.1) {
          updatedCart.splice(existingItemIndex, 1);
        }

        return updatedCart;
      } else {
        return [...prevCart, newItem];
      }
    });
  };

  const removeFromCart = (idPro) => {
    const updatedCart = cart.filter(item => item.idPro !== idPro);
    setCart(updatedCart);
  };

  const clearCart = () => {
    setCart([])
  }

  const countUniqueProducts = () => {
    const uniqueProducts = new Set(cart.map(item => item.idPro));
    return uniqueProducts.size;
  };

  return (
    <CartContext.Provider value={{ cart, setCart, addItemToCart, subtractItemToCart, removeFromCart, clearCart, countUniqueProducts, user, updateUser  }}>
      {children}
    </CartContext.Provider>
  );
};

export { CartContext, CartProvider };
