import React, { useState, useEffect, useContext } from "react";
import Avatar from "react-avatar";
import { BarLoader } from "react-spinners";
import { HomeOutline, ReaderOutline, PersonOutline } from "react-ionicons";

import { CartContext } from "../context/CartContext";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

import "../css/css-pages/clientepage.css";

function ClientePage() {
  const { updateUser, user } = useContext(CartContext);
  const [nombreUsuario, setNombreUsuario] = useState("");
  const [userId, setUserId] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clienteData, setClienteData] = useState({
    cod: "",
    usuario: "",
    nombre: "",
    dni: "",
    telefono: "",
    email: "",
    direccion: "",
    timbre: "",
    contrasena: "",
  });

  const nombreGuardado = user;
  const userIdGuardado = localStorage.getItem("userId");

  useEffect(() => {
    document.title = "Mi Perfil - Erika";

    if (nombreGuardado) {
      setNombreUsuario(nombreGuardado);
    }

    if (userIdGuardado) {
      setUserId(userIdGuardado);
    }

    if (userIdGuardado) {
      fetch(
        `https://api.erikapolleria.com/index.php?route=datoscliente&cod=${userIdGuardado}`
      )
        .then((response) => response.json())
        .then((data) => {
          setClienteData({
            cod: userId,
            usuario: nombreGuardado,
            nombre: data.nombreCompleto || "",
            dni: data.dni || "",
            telefono: data.telefono || "",
            email: data.email || "",
            direccion: data.direccion || "",
            timbre: data.timbre || "",
            contra: data.contrasena || "",
          });
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
        });
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setClienteData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const dataToSubmit = {
      cod: userIdGuardado,
      usuario: nombreUsuario,
      contrasena: clienteData.contra,
      nombreCompleto: clienteData.nombre,
      dni: clienteData.dni,
      telefono: clienteData.telefono,
      email: clienteData.email,
      direccion: clienteData.direccion,
      timbre: clienteData.timbre,
    };

    updateUser(nombreUsuario);

    fetch(`https://api.erikapolleria.com/index.php?route=cliente`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataToSubmit),
    })
      .then((response) => response.json())
      .then((data) => {
      })
      .catch((error) => {
        console.error("Error actualizando los datos:", error);
      });
    setTimeout(() => {
      setLoading(false);
      setDisabled(true)
    }, 500);
  };

  const [disabled, setDisabled] = useState(true);

  const handleDisabled = () => {
    setDisabled((prevDisabled) => !prevDisabled);
  };

  return (
    <div>
      <NavBar />

      <div className="container text-start mt-4">
        <div className="row d-flex justify-content-center">
          <div className="col-md-8">
            <div className="bannerCont">
              <div className="bannerImg">
                <div className="bannerInfo">
                  <Avatar name={nombreUsuario} size="100" round={true} />
                  <h2 className="ms-2 fw-bold w-100 text-truncate">
                    {nombreUsuario}
                  </h2>
                </div>
              </div>
            </div>

            <form className="p-4 pt-0" onSubmit={handleSubmit}>
              <div className="row">
                <div className="d-flex justify-content-between border-bottom mb-2">
                  <h3 className="icon-link mt-3 mb-0">
                    <PersonOutline
                      className="pb-1"
                      color={"#00000"}
                      height="32px"
                      width="32px"
                    />{" "}
                    <span className="navIconText pt-1">Usuario</span>
                  </h3>
                  <a
                    className="btn btn-outline-primary mt-3 mb-3 pb-1"
                    onClick={handleDisabled}
                    title="Editar"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-pencil-square mb-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path
                        fillRule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                      />
                    </svg>{" "}
                    Editar
                  </a>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputName"
                      className="form-label mb-0"
                    >
                      Nombre de usuario
                    </label>
                    <input
                      type="text"
                      className="form-control inputDatos"
                      id="exampleInputName"
                      name="nombreUsuario"
                      value={nombreUsuario}
                      onChange={(e) => setNombreUsuario(e.target.value)}
                      required
                      disabled={disabled}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label htmlFor="contrasena">Contraseña</label>
                    <div className="input-group rounded inputDatos">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        id="contrasena"
                        name="contra"
                        value={clienteData.contra}
                        onChange={handleChange}
                        required
                        disabled={disabled}
                      />
                      <div className="input-group-append">
                        <button
                          type="button"
                          className="btn border-none"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-eye-slash"
                              viewBox="0 0 16 16"
                            >
                              <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                              <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                              <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-eye"
                              viewBox="0 0 16 16"
                            >
                              {" "}
                              <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                              <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                            </svg>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <h3 className="border-bottom icon-link mt-3">
                  <ReaderOutline
                    className="pb-1"
                    color={"#00000"}
                    height="32px"
                    width="32px"
                  />{" "}
                  <span className="navIconText pt-1">Datos personales</span>
                </h3>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputNombre"
                      className="form-label mb-0"
                    >
                      Nombre y apellido
                    </label>
                    <input
                      type="text"
                      className="form-control inputDatos"
                      id="exampleInputNombre"
                      name="nombre"
                      value={clienteData.nombre}
                      onChange={handleChange}
                      required
                      disabled={disabled}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputDni"
                      className="form-label mb-0"
                    >
                      DNI
                    </label>
                    <input
                      type="number"
                      className="form-control inputDatos"
                      id="exampleInputDni"
                      name="dni"
                      value={clienteData.dni}
                      onChange={handleChange}
                      required
                      disabled={disabled}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputTel"
                      className="form-label mb-0"
                    >
                      Teléfono de contacto
                    </label>
                    <input
                      type="number"
                      className="form-control inputDatos"
                      id="exampleInputTel"
                      name="telefono"
                      value={clienteData.telefono}
                      onChange={handleChange}
                      required
                      disabled={disabled}
                    />

                    <div id="emailHelp" className="form-text">
                      Número de teléfono para consultas.
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label mb-0"
                    >
                      Correo electrónico
                    </label>
                    <input
                      type="email"
                      className="form-control inputDatos"
                      id="exampleInputEmail1"
                      name="email"
                      value={clienteData.email}
                      onChange={handleChange}
                      required
                      disabled={disabled}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <h3 className="border-bottom icon-link mt-3">
                  <HomeOutline
                    className="pb-1"
                    color={"#00000"}
                    height="32px"
                    width="32px"
                  />{" "}
                  <span className="navIconText pt-1">Dirección</span>
                </h3>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputDireccion"
                      className="form-label mb-0"
                    >
                      Dirección completa
                    </label>
                    <input
                      type="text"
                      className="form-control inputDatos"
                      id="exampleInputDireccion"
                      name="direccion"
                      value={clienteData.direccion}
                      onChange={handleChange}
                      required
                      disabled={disabled}
                    />
                    <div id="emailHelp" className="form-text">
                      Calle, número y barrio.
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputTimbre"
                      className="form-label mb-0"
                    >
                      Dpto/Piso
                    </label>
                    <input
                      type="text"
                      className="form-control inputDatos"
                      id="exampleInputTimbre"
                      name="timbre"
                      value={clienteData.timbre}
                      onChange={handleChange}
                      disabled={disabled}
                    />
                    <div id="emailHelp" className="form-text">
                      N° departamento, piso, cuadra, etc.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 px-5 mt-4">
                <button
                  type="submit"
                  className="btn w-100 borderErika btnRegis"
                  disabled={disabled}
                >
                  {loading ? (
                    <BarLoader loading={loading} />
                  ) : (
                    "Guardar cambios"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
export default ClientePage;
