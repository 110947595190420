import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Avatar from "react-avatar";

import { CartContext } from "../context/CartContext";

import {
  PersonOutline,
  InformationCircleOutline,
  PersonCircleOutline,
  DownloadOutline,
  ReceiptOutline,
} from "react-ionicons";

function IconCliente() {
  const { clearCart, user, updateUser } = useContext(CartContext);
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const userIdGuardado = localStorage.getItem("userId");

    if (userIdGuardado) {
      setUserId(userIdGuardado);
    }
  }, []);

  const cerrarSesion = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("userId");
    localStorage.removeItem("cart");

    updateUser("");
    clearCart();
    setUserId(null);
    navigate("/iniciarsesion");
  };

  const iniciarSesion = () => {
    navigate("/iniciarsesion");
  };
  return (
    <div>
      <div className="dropdown-center">
        <button
          className="icon"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {userId ? (
            <Avatar name={user} size="36" round={true} />
          ) : (
            <PersonCircleOutline color={"#00000"} height="40px" width="40px" />
          )}
        </button>
        {userId ? (
          <ul className="dropdown-menu p-2">
            <li>
              <span className="dropdown-item-text icon-link fw-bold">
                <Avatar name={user} size="30" round={true} />{" "}
                <span className="navIconText pt-1">{user}</span>
              </span>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li>
              <Link to={"/mispedidos"} className="dropdown-item icon-link">
                <ReceiptOutline
                  className="pb-1"
                  color={"#00000"}
                  height="20px"
                  width="20px"
                />{" "}
                <span className="navIconText pt-1">Mis pedidos</span>
              </Link>
            </li>
            <li>
              <Link to={"/cliente"} className="dropdown-item icon-link">
                <PersonOutline
                  className="pb-1"
                  color={"#00000"}
                  height="20px"
                  width="20px"
                />{" "}
                <span className="navIconText pt-1">Editar perfil</span>
              </Link>
            </li>
            <li>
              <Link to={"/informacion"} className="dropdown-item icon-link">
                <InformationCircleOutline
                  className="pb-1"
                  color={"#00000"}
                  height="20px"
                  width="20px"
                />{" "}
                <span className="navIconText pt-1">Información</span>
              </Link>
            </li>
            <li>
              <button
                className="dropdown-item icon-link"
                onClick={cerrarSesion}
              >
                <DownloadOutline color={"#00000"} height="20px" width="20px" />{" "}
                <span className="navIconText pt-1">Cerrar sesión</span>
              </button>
            </li>
          </ul>
        ) : (
          <ul className="dropdown-menu p-2">
            <li>
              <button
                className="dropdown-item icon-link"
                onClick={iniciarSesion}
              >
                <PersonOutline color={"#00000"} height="20px" width="20px" />{" "}
                <span className="navIconText pt-1">Iniciar sesión</span>
              </button>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
}
export default IconCliente;
