import letras from "../img/erikaLetrasSolo-Rojo.webp";
import IconCliente from "./IconCliente";
import { Link } from "react-router-dom";

function NavBarSin() {
  return (
      <div className="cuerpo mt-3 pb-4">
        <div className="container">
          <div className="row">
            <Link to="/rubros" className="col-6 col-md-3 navbarLeft">
              <img src={letras} className="logo" alt="logo" />
            </Link>
            <div className="col-6 order-md-3 col-md-3 justify-content-end navbarRight">
              <IconCliente />
            </div>
          </div>
        </div>
      </div>
  );
}

export default NavBarSin;
